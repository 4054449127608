import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import CMQ from './CMQ';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {MenuProvider} from "./context/MenuContext";
import {NavigationProvider} from "./context/NavigationContext";
import {WorkListProvider} from './context/WorkListTypeContext';
import {Provider} from "react-redux";
import {store} from "./store";
import {LanguageProvider} from "./context/LanguageContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <LanguageProvider>
                <Provider store={store}>
                    <NavigationProvider>
                        <WorkListProvider>
                            <MenuProvider>
                                <BrowserRouter>
                                    <CMQ/>
                                </BrowserRouter>
                            </MenuProvider>
                        </WorkListProvider>
                    </NavigationProvider>
                </Provider>
            </LanguageProvider>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
