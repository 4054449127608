import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type {ProjectPosts} from "../models/projectPost";
import {sendGetRequest} from "../utils/axios";

interface ProjectsState extends ProjectPosts {
    loading: boolean;
    error: string | null;
}

// Stato iniziale
const initialState: ProjectsState = {
    posts: [],
    loading: false,
    error: null,
};

// Thunk per ottenere le impostazioni del sito dall'API
export const fetchProjects = createAsyncThunk<ProjectPosts>('projects/fetchProjects', async () => {
    return await sendGetRequest<ProjectPosts>(`projects/all`);
});

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                // state.posts = action.payload.posts;
                state.posts = action.payload.posts.sort((a,b) => {
                    const dateA = new Date(a.custom_fields.info.date).getTime()
                    const dateB = new Date(b.custom_fields.info.date).getTime()
                    if (dateA === dateB){
                        return b.post_title.localeCompare(a.post_title)
                    }

                    return dateB - dateA
                })
                state.loading = false;
            })
            .addCase(fetchProjects.rejected, (state) => {
                state.error = 'Errore nel caricamento dei progetti.';
                state.loading = false;
            });
    },
});

export default projectsSlice.reducer;
