import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

export const BASE_URL = "https://cmq-wp.s4staging.it/{lang}wp-json/wp/v2";

const axiosInstance = axios.create({
    baseURL: "https://cmq-wp.s4staging.it/wp-json/wp/v2"
});

export const sendGetRequest = async <T>(request: string, headers?: AxiosRequestConfig): Promise<T | undefined> => {
    try {
        const response: AxiosResponse<T> = headers
            ? await axiosInstance.get<T>(`${request}`, headers)
            : await axiosInstance.get<T>(`${request}`);

        console.warn(response.request.responseURL);

        return response.data;
    } catch (err) {
        console.error("Errore in sendGetRequest:", err);
        return undefined;
    }
};

export default axiosInstance;
