import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PageOptions } from 'src/models/pageOptions';
import {sendGetRequest} from "../utils/axios";

interface PageOptionsState extends PageOptions {
    loading: boolean;
    error: string | null;
}

// Stato iniziale
const initialState: PageOptionsState = {
    benefit: null,
    people: null,
    salotto: null,
    work: null,
    loading: true,
    error: null,
};

// Thunk per ottenere le impostazioni del sito dall'API
export const fetchPageOptions = createAsyncThunk<PageOptions>('pageOptions/fetchPageOptions', async () => {
    return await sendGetRequest<PageOptions>(`page-options/all`);
});

const pageOptionsSlice = createSlice({
    name: 'pageOptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageOptions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPageOptions.fulfilled, (state, action) => {
                state.benefit = action.payload.benefit;
                state.people = action.payload.people;
                state.salotto = action.payload.salotto;
                state.work = action.payload.work;
                state.loading = false;
            })
            .addCase(fetchPageOptions.rejected, (state) => {
                state.error = 'Errore nel caricamento delle pagine.';
                state.loading = false;
            });
    },
});

export default pageOptionsSlice.reducer;
