import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { SiteOptions } from 'src/models/siteOptions';
import {sendGetRequest} from "../utils/axios";

interface SiteOptionsState extends SiteOptions {
    loading: boolean;
    error: string | null;
}

// Stato iniziale
const initialState: SiteOptionsState = {
    site_logo: null,
    homepage: {
        background_image: null,
        static_text: "",
        dynamic_texts: []
    },
    contacts: {
        email_address: "",
        address: ""
    },
    location: {
        info: {
            title: "",
            text: ""
        },
        google_maps: {
            api_key: "",
            default_center: {
                lat: "",
                lng: "",
            },
            markers: []
        }
    },
    newsletter_subscription: {
        logo: null,
        title: "",
        subtitle: "",
        text: "",
        email_placeholder: "",
        policy_acceptance_label: "",
        submit_button_label: ""
    },
    page_selectors: {
        benefit: "",
        alt_benefit: "",
        list: "",
        image: ""
    },
    menu_items: [],
    loading: false,
    error: null,
};

// Thunk per ottenere le impostazioni del sito dall'API
export const fetchSiteOptions = createAsyncThunk<SiteOptions>('siteOptions/fetchSiteOptions', async () => {
    return await sendGetRequest<SiteOptions>(`site-options/all`);
});

const siteOptionsSlice = createSlice({
    name: 'siteOptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSiteOptions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSiteOptions.fulfilled, (state, action) => {
                state.site_logo = action.payload.site_logo;
                state.homepage = action.payload.homepage;
                state.menu_items = action.payload.menu_items;
                state.contacts = action.payload.contacts;
                state.location = action.payload.location;
                state.newsletter_subscription = action.payload.newsletter_subscription;
                state.page_selectors = action.payload.page_selectors;
                state.loading = false;
            })
            .addCase(fetchSiteOptions.rejected, (state) => {
                state.error = 'Errore nel caricamento delle opzioni del sito.';
                state.loading = false;
            });
    },
});

export default siteOptionsSlice.reducer;
