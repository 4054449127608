import { createContext, useState } from "react";
import { Language } from "../enum/Language";
import axiosInstance, { BASE_URL } from "../utils/axios";

type LanguageContextType = {
    language: string;
    updateLanguage: (language: Language) => void;
}

export const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

export const LanguageProvider = ({ children }) => {

    const getLanguageFromCookie = () => {
        const value = `; ${document.cookie}`.split(`; lang=`);

        if (value.length === 2) {
            const val = value.pop().split(";").shift();

            if (val === "it") {
                axiosInstance.defaults.baseURL = BASE_URL.replace("{lang}", "it/");

                return Language.IT;
            }

            return Language.EN;
        }

        console.log("NO VAL - automatic in ENG.");

        return Language.EN;
    }

    const [language, setLanguage] = useState<Language>(getLanguageFromCookie());

    const updateLanguage = (language: Language) => {
        const date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)); // 1 anno;
        console.log(language);

        switch (language) {
            case Language.IT:
                axiosInstance.defaults.baseURL = BASE_URL.replace("{lang}", "it/");
                document.cookie = `lang=it; expires=${date.toUTCString()}; path=/`;
                break;
            default:
                axiosInstance.defaults.baseURL = BASE_URL.replace("{lang}", "");
                document.cookie = "lang=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                break;
        }

        setLanguage(language);
    }

    return (
        <LanguageContext.Provider value={{ language, updateLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}
